@import url(https://fonts.googleapis.com/css?family=Dancing+Script|Montserrat:700);
.font-dancing {
  font-family: 'Dancing Script', cursive;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.slidea-dot {
  display: inline-block;
  color: white;
  font-size: 2rem;
}

.square-border-vertical {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.square-border {
  border: 1px solid white;
}

#slide-delicious {
  margin-top: -2rem;
}

.menu-list {
  list-style: none;
}

.menu-list .menu-title {
  color: white;
  font-size: 2.4rem;
  margin-top: 2rem;
}

.menu-list .menu-description {
  font-size: 1.2rem;
  margin-top: 0rem;
  color: #bdbdbd;
}

.menu-list .menu-price {
  font-size: 1.5rem;
  color: white;
}

@media screen and (max-width: 767px) {
  .slidea .margin-4x {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .slidea .padding-4x {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
